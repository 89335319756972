import React from "react";
import {animateScroll as scroll, Link} from 'react-scroll';
import {NavLink} from "react-router-dom";

export const NtFooter = () => {
    const scrollToTop = () => {
        scroll.scrollToTop();
    }


    return (
        <div className="background-wrapper" >
            <div className='app-content-container'>
                <div className='top-container'>
                    <div onClick={scrollToTop} className='main-logo'>
                        <img src="/images/main-logo.png"
                             alt="main-logo"
                        />
                    </div>
                    <div className="phone-block">
                        <span className='number'>+7 (926) 169-31-91</span>
                        <span className='e-mail'>info@ntdev.ru</span>
                    </div>
                    <p>Мы реализуем проекты в области развития корпоративных ИТ-систем, цифровизации государственных и
                        коммерческих предприятий.</p>
                </div>
                <div className="bottom-container">
                    <div className='empty'></div>
                    <div className="nav">

                        <Link to='aboutBlock' spy={true} smooth={true} duration={500}>
                            О компании
                        </Link>
                        <Link to='devDescription' spy={true} smooth={true} duration={500}>
                            Направления
                        </Link>
                        <Link to='workFlow' spy={true} smooth={true} duration={500}>
                            Этапы работы
                        </Link>
                    </div>
                    <NavLink to='/policy' className="disclaimer">
                        Политика обработки персональных данных
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
