import React, {useEffect, useRef, useState} from "react";

export const DevDescription = () => {

    let backgroundWrapperRef = useRef()
    let [isInViewport, setIsInViewport] = useState(false)
    let loadedClass = isInViewport ? 'loaded' : ''

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                //Появление элемента на экране
                if (entry.isIntersecting) {
                    setIsInViewport(true)
                }
            });
        });

        observer.observe(backgroundWrapperRef.current);
        return () => {
            observer.disconnect();
            setIsInViewport(false)
        };
    }, []);


    return (
        <section className='dev-description-block background-wrapper'>
            <div className={`image-position-wrapper ${loadedClass}`}
                 ref={backgroundWrapperRef}
            >
                <div className="dev-description-block-header app-content-container">
                    <h2 className='text white-32-20'>
                        Направления разработки
                    </h2>
                    <div className="description">
                        <article className='description-title'>
                            Разрабатываем и внедряем программные системы для
                            автоматизации
                            процесса закупок, работы с клиентской базой, управления хозяйственной деятельностью
                            компании
                            и
                            персоналом.
                        </article>
                        <p className='description-subtitle'>
                            Готовы предложить оптимальное решение вашей задачи по
                            цифровизации отдельных процессов или бизнеса в целом.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
