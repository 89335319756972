import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import config from '../../config.json'
import {useForm} from "react-hook-form";


export const ContactForm = () => {

    //Отслеживание viewport
    let backgroundWrapperRefContacts = useRef()
    let [isInViewport, setIsInViewport] = useState(false)
    let loadedClass = isInViewport ? 'loaded' : ''

    //Форма
    const [loading, setLoading] = useState(false)
    const [sendingStatus, setSendingStatus] = useState(null)
    const {
        register, handleSubmit, reset, getValues,
        formState, formState: {errors}, watch,
    } = useForm();
    const watchAllFields = watch()
    const values = getValues()

    axios.defaults.baseURL = config.apiPath;

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                //Появление элемента на экране
                if (entry.isIntersecting) {
                    setIsInViewport(true)
                }
            });
        });

        observer.observe(backgroundWrapperRefContacts.current);

        return () => {
            observer.disconnect();
            setIsInViewport(false)
        };
    }, []);

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset();
            reset({phone: ''})
        }
    }, [formState, reset])

    useEffect(() => {
        if (values.name || values.company || values.email || values.message) {
            setSendingStatus(null)
        }
        //eslint-disable-next-line
    }, [watchAllFields])

    const contactFormSubmit = (data) => {
        console.log(data)
        setLoading(true)
        axios.post('/feedback/', data)
            .then(() => {
                setLoading(false)
                setSendingStatus('success')
            })
            .catch((err) => {
                setLoading(false)
                setSendingStatus('error')
            })
    }

    const errorMessage = () => (
        <p className='error-validation-message'>Обязательное поле</p>
    )

    const successSendMessage = () => (
        <div className={`sending-status animate__animated animate__zoomIn animate__faster ${sendingStatus}`}>
            <img src="/images/contact-form/success-sending.png" alt="success-sending"/>
            <span>Отправлено, мы свяжемся с Вами</span>
        </div>
    )
    const errorSendMessage = () => (
        <div className={`sending-status animate__animated animate__zoomIn animate__faster ${sendingStatus}`}>
            <img src="/images/contact-form/error-sending.png" alt="error-sending"/>
            <span>Ошибка отправки</span>
        </div>
    )

    const numberCountCheck = (value) => {
        const symbolsToRemove = /[-()+\s]/g;
        const parsedValue = value.replace(symbolsToRemove, '')
        const pattern = /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/
        return !!parsedValue.match(pattern) && parsedValue.length === 11
    }
    const computeInputClassName = (inputName) => {
        let classNameResult = ''
        if (values[inputName]) {
            classNameResult = errors[inputName] ? 'error' : ''
        } else {
            classNameResult = errors[inputName] ? 'error' : 'empty'
        }
        return classNameResult
    }

    return (
        <section className='contact-form-block'>
            <div className="background-wrapper">
                <div className={`image-position-wrapper ${loadedClass}`}
                     ref={backgroundWrapperRefContacts}
                >
                    <div className="contact-form-block-header app-content-container">
                        <h2 className='title'>Давайте обсудим ваш проект</h2>
                        <form onSubmit={handleSubmit(contactFormSubmit)}
                              className="form-container"
                        >

                            <label htmlFor="name">
                                <div className="d-flex">
                                    <span>{values.name ? 'Ваше имя*' : ' '}</span>
                                    {errors.name && errorMessage()}
                                </div>
                            </label>
                            <input placeholder='Ваше имя*'
                                   type="text"
                                   id="name"
                                   name='name'
                                   {...register("name", {
                                       required: true
                                   })}
                                   className={computeInputClassName('name')}
                            />

                            <label htmlFor="company">
                                <div className="d-flex">
                                    <span>{values.company ? 'Ваша компания*' : ' '}</span>
                                    {errors.company && errorMessage()}
                                </div>
                            </label>
                            <input placeholder='Ваша компания*'
                                   type="text"
                                   id="company"
                                   name='company'
                                   {...register("company", {
                                       required: true
                                   })}
                                   className={computeInputClassName('company')}
                            />

                            <label htmlFor="phone">
                                <div className="d-flex">
                                    <span>{values.phone ? 'Телефон *' : ' '}</span>
                                    {errors.phone &&
                                        <p className='error-validation-message'>Пример номера 7**********</p>}
                                </div>
                            </label>
                            <input type="tel"
                                   id='phone'
                                   name='phone'
                                   placeholder='Контактный номер*'
                                   className={computeInputClassName('phone')}
                                   {...register("phone",
                                       {
                                           required: true,
                                           validate: v => numberCountCheck(v)
                                       })}
                            />
                            <label htmlFor="email">
                                <div className="d-flex">
                                    <span>{values.email ? 'E-mail*' : ' '}</span>
                                    {errors.email && <p className='error-validation-message'>Неправильный адрес</p>}
                                </div>
                            </label>
                            <input placeholder='E-mail*'
                                   type="text"
                                   id="email"
                                   name='email'
                                   {...register("email",
                                       {
                                           required: true,
                                           pattern: /(.+)@(.+){2,}\.(.+){2,}/
                                       })}
                                   className={computeInputClassName('email')}
                            />

                            <label htmlFor="message">
                                <div className="d-flex">
                                    <span>{values.message ? 'Коротко опишите суть вашей задачи*' : null}</span>
                                    {errors.message && errorMessage()}
                                </div>
                            </label>
                            <textarea placeholder='Коротко опишите суть вашей задачи*'
                                      id="message"
                                      name='message'
                                      className={computeInputClassName('message')}
                                      {...register("message", {
                                          required: true
                                      })}
                            />
                            {/*<label htmlFor="upload">*/}
                            {/*    <input type="file" id="upload" name="file" multiple/>*/}
                            {/*    <img src='/images/contact-form/upload.png'/>*/}
                            {/*    <span className='upload-span'>Выбрать файл</span>*/}
                            {/*</label>*/}
                            <button className='btn-black'
                                    id='sent'
                                    disabled={loading}
                                    type='submit'
                            >
                                Отправить заявку
                            </button>
                            {sendingStatus === 'success' ? successSendMessage() : errorSendMessage()}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
