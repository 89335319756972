import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {CasesPage} from "./components/blocks/CasesPage";
import {PolicyPage} from "./components/blocks/PolicyPage";
import './index.css';
import ErrorBoundary from "./components/ErrorBoundary";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorBoundary/>
    },
    {
        path: '/:projectId',
        element: <CasesPage/>,
        errorElement: <ErrorBoundary/>
    },
    {
        path: '/policy',
        element: <PolicyPage/>,
        errorElement: <ErrorBoundary/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
        <RouterProvider router={router}/>
);

