import React, {useState} from "react";

export const ProgressiveImage = ({
                                     blurImageUrl,
                                     mainImageUrl,
                                     currentRef,
                                     className,
                                     altAttr,
                                 }) => {
    let [imageLoadingState, setImageLoadingState] = useState(false)

    return (
        <img src={imageLoadingState ? mainImageUrl : blurImageUrl }
             alt={altAttr}
             className={className}
             onLoad={() => setImageLoadingState(true)}
             ref={currentRef}
        />
    )
}