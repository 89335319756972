import React from "react";
import 'animate.css';
import {animateScroll as scroll, Link} from 'react-scroll';

export const NtHeader = () => {
    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <div className='animate__animated animate__bounceInDown flex-default-space-between app-content-container'>
            <nav>
                <div onClick={scrollToTop} className='main-logo'>
                    <img src="/images/main-logo.png"
                         alt="main-logo"
                    />
                    <img src="/images/main-logo-hovered.png"
                         alt="main-logo-hovered"
                    />
                </div>
                <div className="nav-center">
                    <Link to='aboutBlock' spy={true} smooth={true} duration={500}>
                        О компании
                    </Link>
                    <Link to='devDescription' spy={true} smooth={true} duration={500}>
                        Направления
                    </Link>
                    <Link to='workFlow' spy={true} smooth={true} duration={500}>
                        Этапы работы
                    </Link>
                </div>
            </nav>
            <Link to='contactForm' className="btn-white" spy={true} smooth={true} duration={500}>
                Отправить запрос
            </Link>
        </div>
    )
}
