export const startPageIcons = [
    {
        path: ['/images/start-page/circle.png', '/images/start-page/ellipse.png'],
        text: 'Проектный подход к разработке',
        iconId: 'hover-icon-first',
    },
    {
        path: ['/images/start-page/ellipse-s.png', '/images/start-page/circle.png', '/images/start-page/ellipse.png'],
        text: 'Передовые технологии программирования',
        iconId: 'hover-icon-second',
    },
    {
        path: ['/images/start-page/rectangle.png', '/images/start-page/circle.png'],
        text: 'Максимальная включенность на всех этапах',
        iconId: 'hover-icon-third',
    },

]

export const aboutBlockCards = [
    {
        imagePath: [`/images/about-block/cards/round.png`, `/images/about-block/cards/straight.png`],
        title: 'Импортозамещение',
        description: 'Развиваем и поддерживаем российский сектор IT-индустрии. Используем собственные наработки в сочетании с программными продуктами, основанными на использовании открытого кода.',
        cardId: 'import-card'
    },
    {
        imagePath: [`/images/about-block/cards/digital-card/img.png`, `/images/about-block/cards/digital-card/img.png`, `/images/about-block/cards/digital-card/img.png`, `/images/about-block/cards/digital-card/img.png`],
        title: 'Цифровизация',
        description: 'Обеспечиваем плавный переход на современные программные решения и технологии без потери исторических данных для банков, коммерческих и государственных структур.',
        cardId: 'digital-card'
    },
    {
        imagePath: [`/images/about-block/cards/straight.png`, `/images/about-block/cards/round.png`, `/images/about-block/cards/straight.png`,
            `/images/about-block/cards/round.png`, `/images/about-block/cards/straight.png`, `/images/about-block/cards/straight.png`, `/images/about-block/cards/round.png`, `/images/about-block/cards/straight.png`, `/images/about-block/cards/round.png`,
            `/images/about-block/cards/straight.png`, `/images/about-block/cards/round.png`, `/images/about-block/cards/straight.png`, `/images/about-block/cards/round.png`, `/images/about-block/cards/round.png`, `/images/about-block/cards/round.png`,
            `/images/about-block/cards/round.png`],
        title: 'Заказная разработка',
        description: 'Подходим к разработке комплексно, начинаем со сбора требований и анализа данных, проектируем, разрабатываем и вводим в эксплуатацию. Сопровождаем проект после сдачи.',
        cardId: 'develop-card'
    }
]

export const workFlowCards = [
    {
        title: 'Сбор требований к системе и составление технического задания',
        result: 'Задокументированное описание способов реализации системы для передачи в разработку.',
        descriptionList: [
            {
                icon: '/images/workflow-block/rounded-center.png',
                text: 'Формирование перечня необходимой функциональности системы'
            },
            {
                icon: '/images/workflow-block/left.png',
                text: 'Сбор технических требований к системе и согласование технологического стека'
            },
            {icon: '/images/workflow-block/rounded-left.png', text: 'Разработка технического задания и спецификаций'},
            {icon: '/images/workflow-block/ellipse-left.png', text: 'Оценка и планирование работ по проекту'}
        ]
    },
    {
        title: 'Проектирование',
        result: 'Задокументированное описание способов реализации системы для передачи в разработку.',
        descriptionList: [
            {
                icon: '/images/workflow-block/rounded-center.png',
                text: 'Разработка модели данных, структуры и ролей в системе'
            },
            {icon: '/images/workflow-block/left.png', text: 'Проектирование архитектуры системы'},
            {icon: '/images/workflow-block/rounded-left.png', text: 'Разработка программной документации'},
        ]
    },
    {
        title: 'Разработка и отладка системы',
        result: 'Готовность системы к развертыванию в тестовой среде заказчика и функциональному тестированию.',
        descriptionList: [
            {icon: '/images/workflow-block/rounded-center.png', text: 'Бэкенд-разработка'},
            {icon: '/images/workflow-block/left.png', text: 'Фронтенд-разработка'},
            {icon: '/images/workflow-block/rounded-left.png', text: 'Внутреннее тестирование и отладка системы'},
            {icon: '/images/workflow-block/ellipse-left.png', text: 'Подготовка сопровождающей документации'},
            {
                icon: '/images/workflow-block/rounded-rectangle-center.png',
                text: 'Функциональное тестирование заказчиком'
            }
        ]
    },
    {
        title: 'Приемо-сдаточные испытания',
        result: 'Готовность системы к вводу в опытную эксплуатацию.',
        descriptionList: [
            {
                icon: '/images/workflow-block/rounded-center.png',
                text: 'Проведение приемо-сдаточных испытаний согласно подготовленной методике'
            },
            {
                icon: '/images/workflow-block/left.png',
                text: 'Устранение замечаний и выявленных несоответствий техническому заданию'
            },
        ]
    },
    {
        title: 'Опытная эксплуатация',
        result: 'Готовность системы к вводу в промышленную эксплуатацию.',
        descriptionList: [
            {
                icon: '/images/workflow-block/rounded-center.png',
                text: 'Эксплуатация системы на ограниченном числе пользователей в течение оговоренного срока'
            },
            {icon: '/images/workflow-block/left.png', text: 'Устранение выявленных недочетов'},
            {icon: '/images/workflow-block/rounded-left.png', text: 'Обучение персонала заказчика'},
        ]
    },
    {
        title: 'Перевод в промышленную эксплуатацию, техническая поддержка и развитие системы',
        result: 'Работающая система, обеспечивающая выполнение заложенных в неё функциональных возможностей и расширение этих возможностей.',
        descriptionList: [
            {icon: '/images/workflow-block/rounded-center.png', text: 'Ввод системы в промышленную эксплуатацию'},
            {
                icon: '/images/workflow-block/left.png',
                text: 'Сопровождение системы — вторая и третья линии технической поддержки'
            },
            {
                icon: '/images/workflow-block/rounded-left.png',
                text: 'Модификация и расширение функциональных возможностей системы по новым требованиям заказчика'
            },
        ]
    },
]
export const portfolioCards = [
    {
        cardId: 'automation',
        title: 'Автоматизация процесса закупок',
        description: 'Проектируем, разрабатываем и развиваем системы автоматизации мониторинга торговых площадок и участия в тендерах. В зависимости от требований заказчика могут быть оптимизированы разные этапы: от размещения позиций до получения отчетности.',
        backgroundIcons: [`/images/portfolio-block/cases-page/cards/automation-card/circle.png`, `/images/portfolio-block/cases-page/cards/automation-card/ellipse.png`],
    },
    {
        cardId: 'integration',
        title: 'Интеграционные решения',
        description: 'Вникаем в существующую IT-инфраструктуру предприятий. Разрабатываем и внедряем совместимые решения. Предлагаем оптимизацию.',
        backgroundIcons: [`/images/portfolio-block/cases-page/cards/integration-card/circle.png`, `/images/portfolio-block/cases-page/cards/integration-card/rectangle.png`],
    },
    {
        cardId: 'pre-project',
        title: 'Предпроектные работы',
        description: 'Участвуем в предпроектной работе. Глубоко погружаемся в задачу, выясняем особенности будущей системы. Проводим исследовательские и опытные работы. Составляем подробные отчеты.',
        backgroundIcons: [`/images/portfolio-block/cases-page/cards/pre-project-card/circle.png`, `/images/portfolio-block/cases-page/cards/pre-project-card/rectangle.png`],
    },
    {
        cardId: 'online-services',
        title: 'Онлайн-сервисы',
        description: 'Разрабатываем удобные пользовательские платформы и сервисы. Продумываем интерфейсы и функциональную начинку проектов. Сопровождаем сервисы после запуска.',
        backgroundIcons: [`/images/portfolio-block/cases-page/cards/online-services-card/circle.png`, `/images/portfolio-block/cases-page/cards/online-services-card/rectangle.png`],
    }

]

export const profileCases = {
    automation: [
        {
            id: 'card1',
            header: 'SAS-решение для автоматизации малых закупок',
            tags: ['Автоматизация закупок', 'Автоматизация продаж', 'SAS', 'Интеграция'],
            headerChip: 'Ритейл',
            stack: ['Java,', 'Spring,', 'PostgreSQL,', 'Apache Kafka'],
            status: ['Система введена в эксплуатацию'],
            caseDescription: 'Разработали систему, которая позволяет коммерческим поставщикам загружать и автоматически обновлять каталог товаров на федеральных торговых площадках. Создали пул торговых роботов и автоматизировали процесс ведения закупок государственным предприятием от этапа поиска необходимого товара до оповещения о результатах сделки.',
            features: [
                'Автоматическое обновление товарных предложений.',
                'Размещение предложений на площадках.',
                'Ведение закупок с помощью торговых роботов. Корректировка уровня цен.',
                'Оповещение о результатах закупок.',
                'Сбор статистики по проведенным торгам.'
            ],
            scheme: '/images/case-modal/schemes/automation/1.png',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-1/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-1/rectangle.png`]
        },
        {
            id: 'card2',
            header: 'Автоматизация взаимодействия с клиентами в сфере услуг',
            tags: ['Автоматизация клиентской базы', ' Автоматизация продаж', 'SAS', 'Интеграция', 'Управленческая статистика'],
            headerChip: 'Сфера услуг',
            stack: ['Java,', 'Spring,', 'PostgreSQL,', 'Apache Kafka'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Создали гибкую CRM-систему для предприятий, работающих в сфере услуг. Комплекс позволяет решать большинство управленческих задач бизнеса. Заводить товары и услуги. Распределять рабочее время сотрудников. Записывать клиентов онлайн и создавать им личные аккаунты. Просматривать отчетность в удобном для клиента формате. ',
            features: [
                'Ведение каталога товаров и услуг предприятия.',
                'Ведение клиентской базы и автоматизация продаж, в том числе возможность онлайн-записи на услуги. Сбор отзывов.',
                'Возможность внести клиента в общую базу при первичном обращении через оффлайн.',
                'Реестр сотрудников и учет рабочего времени.',
                'Возможность проведения СМС- и имейл-рассылок.',
                'Интеграция с внешними сервисами: Google-календарь, Яндекс. Карты, через которые можно записаться на прием специалиста.',
                'Отчеты по продажам для руководителей и другая управленческая статистика.'
            ],
            scheme: '/images/case-modal/schemes/automation/2.png',
            parentBackground: []
        },
        {
            id: 'card3',
            header: 'Автоматизация управления взаимодействием с поставщиками',
            tags: ['Автоматизация базы поставщиков', 'Автоматизация закупок', 'Интеграция', 'Управленческая статистика'],
            headerChip: 'Ритейл',
            stack: ['Java,', 'Spring,', 'PostgreSQL,', 'Apache Kafka'],
            status: ['Система запущена в опытную эксплуатацию'],
            caseDescription: 'Разработали систему для автоматического формирования заявок и закупки товаров у одного или нескольких поставщиков. Система позволяет поставщику зарегистрировать аккаунт и ввести данные о своих товарных позициях. Решение включает в себя взаимодействие с поставщиками через SMS и email-сообщения, формирует управленческую отчетность.',
            features: [
                'Агрегация каталогов товаров и услуг зарегистрированных в системе поставщиков.',
                'Создание заявок на закупку у одного или нескольких поставщиков.',
                'Интеграция с смс-шлюзами и емейл-рассылками для оповещения пользователей о различных событиях в системе.',
                'Отчеты по поставщикам и другие отчеты для руководителей.',
            ],
            scheme: '/images/case-modal/schemes/automation/3.png',
            parentBackground: []
        },
        {
            id: 'card4',
            header: 'Развитие системы формирования годового бюджета\n',
            tags: ['Управление внутрихозяйственной деятельностью', 'Бюджетирование расходов', 'Аналитические отчеты'],
            headerChip: 'Банковский сектор',
            stack: ['Java,', 'Spring,', 'Kotlin,', 'Oracle'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Провели доработку системы формирования годового бюджета для коммерческого банка. Создали возможность подачи заявок региональными отделениями в головное управление по унифицированному формату. Обеспечили автоматическое сведение бюджета по утвержденным заявкам. Настроили сбор аналитики и построение отчетов.',
            features: [
                'Формирование заявок на бюджетирование подразделения банка, головного управления и региональных управлений.',
                'Автоматизация бизнес-процессов согласования заявок на бюджетирование.',
                'Формирование бюджета по утвержденным заявкам.',
                'Построение аналитической отчетности для руководителей.',
            ],
            scheme: '/images/case-modal/schemes/automation/4.png',
            parentBackground: []
        },
        {
            id: 'card5',
            header: 'Омниканальный сервис ведения базы сотрудников',
            tags: ['Управление кадрами', 'Омниканальные сервисы'],
            headerChip: 'Банковский сектор',
            stack: ['Java,', 'SpringBoot,', 'PostgreSQL,', 'Apache Kafka,', 'Kubernetes'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Разработали и внедрили систему сбора и актуализации данных из разных каналов по сотрудникам банковской организации, зависимых и дочерних предприятий. Обеспечили удобный поиск данных в системе. Развиваем и поддерживаем систему. ',
            features: [
                'Ведение и актуализация учетных данных по сотрудникам.',
                'Поиск сотрудников по различным атрибутам.',
                'Публикация инкрементальных обновлений по сотрудникам, что позволяет оперировать актуальными данными, не обновляя всю базу целиком.',
            ],
            scheme: '/images/case-modal/schemes/automation/5.png',
            parentBackground: []
        },
        {
            id: 'card6',
            header: 'Приложение для инвентаризации с фотофиксацией',
            tags: ['Управление внутрихозяйственной деятельностью', 'Управленческая статистика'],
            headerChip: 'Банковский сектор',
            stack: ['Kotlin,', 'Android'],
            status: ['Система введена в эксплуатацию'],
            caseDescription: 'Разработали мобильное приложение для проведения инвентаризации и учета материальных активов банка для устройств под управлением ОС Android.',
            features: [
                'Ведение реестра и учет материальных активов банка.',
                'Проведение инвентаризационных мероприятий с фотофиксацией.',
                'Статистика и аналитика.',
            ],
            scheme: '/images/case-modal/schemes/automation/6.png',
            parentBackground: []
        },
        {
            id: 'card7',
            header: 'Система конфигурирования торговой площадки без изменения кода',
            tags: ['Автоматизация закупок', 'Аттестация ПО', 'Аналитические отчеты'],
            headerChip: 'Госсектор',
            stack: ['PostgreSQL,', 'PHP'],
            status: ['Система введена в эксплуатацию'],
            caseDescription: 'Разработали и провели через аттестацию в государственных структурах платформу для конфигурации закупок в сфере государственного оборонного заказа. Внедрили использование ЭЦП для придания юридической значимости документам. ' +
                'Для гибкости системы в дальнейшей эксплуатации создали язык программирования на базе xml. Он позволяет конфигурировать необходимые бизнес-процессы без изменения кода. Система помогает распределять обязанности между сотрудниками и формировать аналитические отчеты о закупках.',
            features: [
                'Конфигурирование бизнес-процессов по 223-ФЗ и 44-ФЗ без доработок системы.',
                'Ведение юридически значимого документооборота.',
                'Управление правами пользователей для распределения обязанностей между сотрудниками.',
                'Управление контентом открытой части системы.',
                'Сбор статистики и формирование отчетов по закупкам.'
            ],
            scheme: '/images/case-modal/schemes/automation/7.png',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-7/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-7/rectangle.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-7/circle.png`]
        },
        {
            id: 'card8',
            header: 'Модули для государственной торговой площадки',
            tags: ['Автоматизация закупок', 'Интеграция', 'Управленческая статистика'],
            headerChip: 'Госсектор',
            stack: ['PHP,', 'PostgreSQL,', 'Redis,', 'ElasticSearch,', 'Kubernetes'],
            status: ['Система введена в эксплуатацию'],
            caseDescription: 'Разработали систему учета поставщиков товаров и услуг для государственной торговой площадки автоматизации малых закупок. Система позволяет регистрировать поставщиков, автоматически создавать заявки на закупку у одного или нескольких из них. Формировать информативные отчеты по поставщикам.',
            features: [
                'Регистрация поставщика',
                'Каталога товаров и услуг поставщика',
                'Автоматизация заявок на закупку товаров',
                'Интеграция с SMS-шлюзами и сервисами email-рассылок',
            ],
            scheme: '/images/case-modal/schemes/automation/8.png',
            parentBackground: []
        },
        {
            id: 'card9',
            header: 'Система постановки задач и учета рабочего времени',
            tags: ['Управление рабочим процессом', 'Управление внутрихозяйственной деятельностью', 'Аналитические отчеты'],
            headerChip: 'ИТ',
            stack: ['Java,', 'Kotlin,', 'Spring,', 'Oracle'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Разработали систему управления задачами (таск-менеджер) для автоматизации процессов и учета рабочего времени в разработке программного обеспечения. Система позволяет регистрировать пользователей и назначить им задачи. Описывать и графически отображать ход выполнения задач. Формирует отчетность для руководителя для оценки затрат и прогрессу по каждому проекту. ',
            features: [
                'Ведение реестра сотрудников.',
                'Постановка, описание и графическое сопровождение задач проекта.',
                'Учет рабочего времени и контроль выполнения задач.',
                'Отображение динамики выполнения проекта.',
                'Аналитическая отчетность для руководителя по трудозатратам и выполнению проектов.'
            ],
            scheme: '/images/case-modal/schemes/automation/9.png',
            parentBackground: []
        },
        {
            id: 'card10',
            header: 'Сервис массовых рекламных email-рассылок',
            tags: ['SAS', 'Аналитические отчеты'],
            headerChip: 'Реклама',
            stack: ['PHP,', 'PostgreSQL,', 'Redis,', 'ElasticSearch,', 'Kubernetes'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Создали платформу для отправки email-рассылок. Система позволяет формировать списки получателей, создавать и использовать шаблоны писем, отправлять до 130 млн писем в сутки, отслеживать действия адресатов после получения писем. ',
            features: [
                'Загрузка базы подписчиков или сбор контактов с помощью формы подписки. Настройка списков рассылки',
                'Конструктор с готовыми шаблонами писем.',
                'Отправка писем немедленно или по расписанию.',
                'Сбор статистики по открываемости писем и карта кликов.',
                'Контроль спам-сигналов от крупных почтовых сервисов и поддержание рейтинга чистоты рассылок.'
            ],
            scheme: '/images/case-modal/schemes/automation/10.png',
            parentBackground: []
        },
        {
            id: 'card11',
            header: 'Медицинская информационная система для стоматологии\n',
            tags: ['Автоматизация клиентской базы', 'Управление кадрами', 'Управление внутрихозяйственной деятельностью', 'Интеграция', 'Аналитические отчеты'],
            headerChip: 'Сфера услуг',
            stack: ['Java,', 'SpringBoot,', 'PostgreSQL,', 'Apache Kafka,', 'Kubernetes'],
            status: ['Система проходит тестовую эксплуатацию'],
            caseDescription: 'Создали CRM-систему для стоматологических клиник. Система позволяет формировать базу сотрудников и пациентов, планировать рабочее время врачей, вести карты пациентов, назначать им процедуры, продавать товары и услуги, получать информативную аналитику по ключевым показателям работы клиники. ',
            features: [
                'Формирование и ведение реестра сотрудников.',
                'Формирование и ведение реестра пациентов.',
                'Ведение приема, составление зубной карты пациента и списка необходимых процедур.',
                'Продажи товаров и услуг.',
                'Ведение складского и финансового учета.',
                'Аналитика по продажам, пациентам, сотрудникам.',
                'Коммуникация с пациентами через email, SMS и push-уведомления.'
            ],
            scheme: '/images/case-modal/schemes/automation/11.png',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-11/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-11/rectangle.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-11/circle.png`]
        }
    ],
    integration: [
        {
            id: 'card1',
            header: 'Интеграционная шина для обработки голосовых обращений сотрудников',
            tags: ['Интеграция', 'Управление кадрами'],
            headerChip: 'Банковский сектор',
            stack: ['Java,', 'Spring,', 'PostgreSQL,', 'Apache Kafka'],
            status: ['Система введена в эксплуатацию'],
            caseDescription: 'Создали интеграцию между системой управления хозяйственной деятельностью банка и платформой нейролингвистической обработки. Сотрудники банка получили возможность отправки голосовых запросов на оформление командировки, отпуска, справки о заработной плате через корпоративный чат. Через интеграционную шину голосовые сообщения передаются на платформу НЛП, обрабатываются и в виде готовых задач поступают в систему УХД. \n' +
                'Этот же программный комплекс обеспечивает контроль выполнения задач. Система предусматривает подключение и других каналов приема данных без изменения кода и доработки.',
            features: [],
            scheme: '/images/case-modal/schemes/integration/1.png',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-1/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-1/rectangle.png`]
        }
    ],
    'pre-project': [
        {
            id: 'card1',
            header: 'НИОКР по разработке системы анализа рынка товаров в оборонной сфере',
            tags: ['Анализ рынка', 'НИОКР'],
            headerChip: 'Госсектор',
            stack: [],
            status: ['Результаты анализа приняты в дальнейшую работу'],
            caseDescription: 'Провели исследование и предложили свой проект системы для управления закупками в области гособоронзаказа. Проект предусматривал возможность формирования единого общегосударственного каталога промышленных товаров и услуг, мониторинга цен, обоснования выбора поставщика товаров и услуг.',
            features: [],
            scheme: '',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-1/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-1/rectangle.png`]
        }
    ],
    'online-services': [
        {
            id: 'card1',
            header: 'Стриминговый сервис качественной электронной музыки\n',
            tags: ['Стриминг'],
            headerChip: 'B2C',
            stack: ['PHP,', 'postgresql,', 'icast'],
            status: ['Система введена в эксплуатацию', 'Техническая поддержка и доработка'],
            caseDescription: 'Создали платформу для прослушивания электронной музыки. Система позволяет создать личный аккаунт и выполнить индивидуальные настройки плэйлистов каждому пользователю.',
            features: ['Регистрация пользователей.', 'Индивидуальные настройки воспроизведения контента.', 'Автоматическое информирование пользователей сервиса', 'Возможность оформления платных подписок.'],
            scheme: '/images/case-modal/schemes/online-services/1.png',
            parentBackground: [`/images/portfolio-block/cases-page/cards/automation-card/background-1/ellipse.png`, `/images/portfolio-block/cases-page/cards/automation-card/background-1/rectangle.png`]
        }
    ]
}

export const policyData = [
    {
        id: 1,
        title: 'Общие положения',
        list: [
            '1.1 ООО"НТ Разработки" (далее по тексту – Оператор) ставит соблюдение прав и свобод граждан одним из важнейших условий осуществления своей деятельности.',
            '1.2 Политика Оператора в отношении обработки персональных данных (далее по тексту — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта ntdev.ru. Персональные данные обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.'
        ]
    },
    {
        id: 2,
        title: 'Основные понятия, используемые в Политике',
        list: [
            '2.1 Веб-сайт - совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу ntdev.ru;',
            '2.2 Пользователь – любой посетитель веб-сайта ntdev.ru;',
            '2.3 Персональные данные – любая информация, относящаяся к Пользователю веб-сайта ntdev.ru;',
            '2.4 Обработка персональных данных - любое действие с персональными данными, совершаемые с использованием ЭВМ, равно как и без их использования;',
            '2.5 Обезличивание персональных данных – действия, результатом которых является невозможность без использования дополнительной информации определить принадлежность персональных данных конкретному Пользователю или лицу;',
            '2.6 Распространение персональных данных – любые действия, результатом которых является раскрытие персональных данных неопределенному кругу лиц;',
            '2.7 Предоставление персональных данных – любые действия, результатом которых является раскрытие персональных данных определенному кругу лиц;',
            '2.8 Уничтожение персональных данных – любые действия, результатом которых является безвозвратное уничтожение персональных на ЭВМ или любых других носителях.'
        ]
    },
    {
        id: 3,
        title: 'Оператор может обрабатывать следующие персональные данные Пользователя',
        list: [
            '3.1 Список персональных данных, которые обрабатывает оператор: фамилия, имя, отчество, номер телефона, адрес электронной почты.',
            '3.2. Кроме того, на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).'
        ]
    },
    {
        id: 4,
        title: 'Цели обработки персональных данных',
        list: [
            '4.1 Персональные данные пользователя - фамилия, имя, отчество, номер телефона, адрес электронной почты - обрабатываются со следующей целью: Обсуждение деталей заказа клиента. Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес info@ntdev.ru',
            '4.2 Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.'
        ]
    },
    {
        id: 5,
        title: 'Правовые основания обработки персональных данных',
        list: [
            '5.1 Оператор обрабатывает персональные данные Пользователя только в случае их отправки Пользователем через формы, расположенные на веб-сайте ntdev.ru. Отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.',
            '5.2 Оператор обрабатывает обезличенные данные о Пользователе в случае, если Пользователь разрешил это в настройках браузера (включено сохранение файлов «cookie» и использование технологии JavaScript).'
        ]
    },
    {
        id: 6,
        title: 'Порядок сбора, хранения, передачи и других видов обработки персональных данных',
        list: [
            '6.1 Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
            '6.2 Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.',
            '6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их, направив Оператору уведомление с помощью электронной почты на электронный адрес Оператора info@ntdev.ru, либо на почтовый адрес Оператора 117405, Москва, ул. Дорожная, 60А, строение 1, офис 812, с пометкой «Актуализация персональных данных».',
            '6.3 Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление с помощью электронной почты на электронный адрес Оператора info@ntdev.ru, либо на почтовый адрес Оператора 117405, Москва, ул. Дорожная, 60А, строение 1, офис 812, с пометкой «Отзыв согласия на обработку персональных данных».',
        ]
    },
    {
        id: 7,
        title: 'Заключительные положения',
        list: [
            '7.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты info@ntdev.ru, либо на почтовый адрес Оператора 117405, Москва, ул. Дорожная, 60А, строение 1, офис 812.',
            '7.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. В случае существенных изменений Пользователю может быть выслана информация на указанный им электронный адрес.'
        ]
    },
]